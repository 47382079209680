// version spabooking-production-de-d53a7e1
const defaultTheme = require('tailwindcss/defaultTheme')
const { layoutConfig } = require('./src/config')

const selectedLayout = process.env.NEXT_PUBLIC_LAYOUT_NAME || 'frantiskovylazne'

const layoutColors = layoutConfig[selectedLayout].colors

module.exports = {
  content: ['./src/pages/**/*.{js,ts,jsx,tsx}', './src/components/**/*.{js,ts,jsx,tsx}'],
  theme: {
    colors: {
      background: '#FDFDFD',
      card: {
        background: '#F4F6F7',
        default: '#EBF0F2',
        hover: '#E5E7EB',
      },
      danger: {
        highlight: '#FCE9EC',
        main: '#DF4052',
      },
      dark: '#1F2325',
      error: '#DD3044',
      info: {
        highlight: '#FCF3C5',
        main: '#8F4500',
      },
      light: '#FFFFFF',
      muted: '#5E6E76',
      primary: layoutColors.primary,
      secondary: layoutColors.secondary,
      theme: layoutColors.theme,
      transparent: 'transparent',
    },
    extend: {
      minHeight: {
        card: '140px',
      },
    },
    fontFamily: {
      sans: ['Jost', ...defaultTheme.fontFamily.sans],
      serif: ['Prata', ...defaultTheme.fontFamily.serif],
    },
    fontSize: {
      body: ['16px', '20px'],
      'body-mobile': ['16px', '20px'],
      caption: ['10px', '16px'],
      category: ['24px', '48px'],
      'category-mobile': ['20px', '24px'],
      'extra-small': ['11px', '16px'],
      headline: ['56px', '68px'],
      'headline-mobile': ['32px', '44px'],
      small: ['14px', '20px'],
      subcategory: '20px',
      'subcategory-mobile': ['18px', '24px'],
    },
    screens: {
      '2xl': '1536px',
      lg: '1024px',
      md: '768px',
      sm: '640px',
      xl: '1280px',
      xs: '375px',
    },
  },
}
